import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  }, {
    path: '/login',
    name: 'login',
    meta: { title: '85proxy | Login' },
    component: () => import('../views/common/login')
  }, {
    path: '/register',
    name: 'register',
    meta: { title: '85proxy | Register' },
    component: () => import('../views/common/register')
  }, {
    path: '/forgot',
    name: 'forgot',
    meta: { title: '85proxy | Forgot' },
    component: () => import('../views/common/forgot')
  }, {
    path: '/index',
    name: 'Index',
    redirect: '/home',
    component: () => import('../views/home/index'),
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: { title: '85proxy | Home' },
        component: () => import('../views/home/home')
      }, {
        path: '/confirm-order',
        name: 'ConfirmOrder',
        meta: { title: '85proxy | Order' },
        component: () => import('../views/mine/order/confirm')
      }, {
        path: '/faq',
        name: 'Faq',
        meta: { title: '85proxy | FAQ' },
        component: () => import('../views/common/faq')
      }, {
        path: '/mine',
        name: 'Mine',
        component: () => import('../views/mine/center/index'),
        children: [{
          path: '/mine/instru',
          name: 'Instrument',
          meta: { title: '85proxy | Dashboard' },
          component: () => import('../views/mine/instrument/index')
        }, {
          path: '/mine/bill',
          name: 'Bill',
          meta: { title: '85proxy | Bill' },
          component: () => import('../views/mine/bill/index')
        }, {
          path: '/mine/info',
          name: 'Info',
          meta: { title: '85proxy | Info' },
          component: () => import('../views/mine/center/info')
        }, {
          path: '/mine/logs',
          name: 'Logs',
          meta: { title: '85proxy | Logs' },
          component: () => import('../views/mine/logs/index')
        }]
      }
    ]
  }, {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/common/404')
  }
]

const router = new VueRouter({
  mode: 'hash', // hash,istory
  base: process.env.BASE_URL,
  routes
})
// 动态设置页面标题
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
// router.onError((callback) => {
//   callback(new Error('404'))
//   router.push('/404')
// })

export default router
