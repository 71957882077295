export default {
  state: {
    loading: {
      state: false,
      text: ''
    },
    userInfo: null,
    curMenuId: 'home',
    order: null
  },
  getters: {},
  mutations: {
    setLoading(state, params) {
      // console.log(params)
      if (params.state) {
        state.loading.state = params.state
        state.loading.text = params.text
      } else {
        setTimeout(() => {
          state.loading.state = params.state
          state.loading.text = params.text
        }, 1200)
      }
    },
    setUserInfo(state, params) {
      // console.log(params)
      state.userInfo = params
    },
    setCommonState(state, params) {
      // console.log(params)
      state[params.label] = params.value
    }
  },
  actions: {
    
  }
}