import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import createVuexAlong from 'vuex-along'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { common },
  plugins: [
    createVuexAlong({
      name: 'vuex-along',
      justSession: true,
      session: {
        list: ['calling'],
        isFilter: true
      }
    })
  ]
})