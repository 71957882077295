
export function parseTime(timestamp, format) {
  // console.log(timestamp, format)
  if (timestamp) {
    if (timestamp.toString().length === 10) {
      var date = new Date(timestamp * 1000)
    } else {
      var date = new Date(timestamp)
    }
    const timeObj = {
      yyyy: date.getFullYear(),
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      hh: date.getHours(),
      mi: date.getMinutes(),
      ss: date.getSeconds(),
      ms: date.getMilliseconds(),
      ww: date.getDay()
    }
    // console.log(timeObj)
    const timeStr = format.replace(/(yyyy|mm|dd|hh|mi|ss|ms|ww)+/g, (result, key) => {
      // console.log(result, key)
      let value = timeObj[key]
      if (key === 'yyyy') {

      } else if (key === 'ms') {
        if (value < 10) {
          value = '00' + value
        } else if (value < 100) {
          value = '0' + value
        }
      } else if (key === 'ww') {
        return ['week1', 'week2', 'week3', 'week4', 'week5', 'week6', 'week7'][value - 1]
      } else {
        if (result.length > 0 && value < 10) value = '0' + value
      }
      // console.log(value)
      return value
    })
    // console.log(timeStr)
    return timeStr
  } else {
    return ''
  }
}

export function addCommas(num) {
  // console.log(num)
  if (num) {
    const numStr = num.toString();
    const result = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return result;
  } else {
    return num;
  }
}
